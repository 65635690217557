<template>
  <div>
    <el-dialog
      :visible.sync="newContactDialogVisible"
      class="new-dialog new-contacts"
      :close-on-click-modal="false"
    >
      <div class="mod-title" slot="title">{{ cc_title }}</div>
      <div class="cont">
        <div>
          <el-form ref="form" :model="form" label-width="60px">
            <el-form-item label="姓名">
              <el-input
                v-model="form.name"
                class="w-200"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input
                v-model="form.email"
                class="w-200"
                placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机">
              <el-input
                v-model="form.phone"
                class="w-200"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="form.description"
                class="w-200"
                placeholder="请输入备注信息"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="button-group mar-t-40">
            <el-button
              type="primary"
              round
              :disabled="loading"
              v-loading="loading"
              @click="add()"
              >确定</el-button
            >
            <el-button round @click="newContactDialogVisible = false"
              >取消</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 关注公众号 -->
    <el-dialog
      :close-on-click-modal="false"
      @closed="operationDone"
      class="new-dialog"
      :visible.sync="qrcodeDialogVisible"
    >
      <div class="mod-title" slot="title">绑定预警公众号</div>
      <div class="cont flex">
        <div class="ov-h">
          <qrcode :url="wechatFocusUrl" />
          <p class="mar-t-10 t-c lh-30">
            微信扫一扫<br />
            关注舆情公众号 接收预警
          </p>
        </div>
      </div>
    </el-dialog>
    <!--@end 关注公众号 -->
  </div>
</template>
<script>
import qrcode from '@components/common/qrcode-item.vue';
import { create_v2 as contactCreate } from '@/api/contacts';
import { isPhone, isEmail } from '@/utils/helpers';
import { mapGetters } from 'vuex';
const _ = window._;
export default {
  props: ['title'],
  data() {
    return {
      newContactDialogVisible: false,
      qrcodeDialogVisible: false,
      form: {
        id: 0,
        name: '',
        email: '',
        phone: '',
        description: ''
      },
      loading: false,
      wechatFocusUrl: '',
      cc_title: this.title ? this.title : '新增联系人'
    };
  },
  components: {
    qrcode
  },
  computed: {
    ...mapGetters(['bundle'])
  },
  mounted() {
  },
  methods: {
    show() {
      this.newContactDialogVisible = true;
      this.initNewContact();
    },
    add() {
      let contact = JSON.parse(JSON.stringify(this.form));
      if (_.size(contact.name) < 2 || _.size(contact.name) > 18) {
        this.$message.warning('请填写联系人姓名，2~18个字符');
        return false;
      }
      // 微信和邮箱不能同时为空 可仅填写邮箱，填写微信时邮箱或手机号码必填其一
      if (!contact.email && !contact.phone) {
        this.$message.warning('email和手机号不能同时为空');
        return false;
      }
      if (_.size(contact.email) >= 1 && !isEmail(contact.email)) {
        this.$message.warning('email格式错误');
        return false;
      }
      if (_.size(contact.phone) >= 1 && !isPhone(contact.phone)) {
        this.$message.warning('请填写正确的手机号码');
        return false;
      }
      if (this.bundle.contacts.remain <= 0) {
        this.$message.warning('当前套餐联系人数量已达上限');
        return false;
      }
      // 添加
      this.loading = true;
      contactCreate(contact)
        .then(res => {
          if (res.data.state) {
            this.$message.success('保存成功');
            this.newContactDialogVisible = false;
            this.wechatFocusUrl = res.data.data.subscribe_url;
            if (contact.id != 0) {
              this.operationDone();
              this.initNewContact();
            } else {
              this.bundle.contacts.remain = this.bundle.contacts.remain - 1;
              this.$store.dispatch('storeBundle', this.bundle);
              this.qrcodeDialogVisible = true;
            }
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(res => {
          this.loading = false;
          this.$message.error('创建联系人失败，服务错误');
        });
    },
    operationDone() {
      this.loading = false;
      try {
        //当父组件为通讯录管理时，刷新contact list
        this.$parent.loadContacts();
      } catch (e) {
        window.console.log(
          'try to relaod the contact list from $parent got FAULT, maybe the parent page are not contact-management.'
        );
      }
    },
    initNewContact() {
      this.form = {
        id: 0,
        name: '',
        email: '',
        phone: '',
        description: ''
      };
    }
  }
};
</script>
<style scoped>
/*关注公众号*/
.fzgzh .cont {
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.fzgzh .cont img {
  margin: 0 auto;
  display: block;
}
.fzgzh .cont p {
  line-height: 24px;
  text-align: center;
}
</style>
