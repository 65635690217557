<template>
  <div class="w-100 week-management">
    <el-row class="empty" v-if="!loading && data.length < 1">
      <div>
        <img
          src="@/assets/images/common/empty/zbkzt.svg"
          alt="周报空状态"
          width="400"
          class="img"
          type="image/svg+xml"
        />
        <p>
          <span>什么都没有，先来设置方案吧</span
          ><el-button type="primary" round @click="toPlan">设置方案</el-button>
        </p>
      </div>
    </el-row>
    <div class="min-h-600 pb-20" v-loading="loading" v-else>
      <div class="mod-list lh-60 average-list">
        <ul>
          <li>
            <h3>
              <span class="num-tit">序号</span>
              <span class="tag">方案名称</span>
              <span class="tag">报告状态</span>
              <span class="tag">发送方式</span>
              <span class="tag">发送时间</span>
              <span class="tag">套用模版</span>
              <span class="tag">操作</span>
            </h3>
          </li>
          <li v-for="(row, index) in data" :key="index">
            <div class="item">
              <span class="num bg-555" v-if="index == 0">1</span>
              <span class="num bg-689" v-else-if="index == 1">2</span>
              <span class="num bg-8ea" v-else-if="index == 2">3</span>
              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
              <span class="tag">{{ row.name }}</span>
              <span class="tag">
                {{ row.enable == 'on' ? '启用' : '停用' }}
                <el-switch
                  @change="changeState(row)"
                  v-model="row.enable"
                  active-value="on"
                  inactive-value="off"
                >
                </el-switch>
              </span>
              <span class="tag">{{
                row.send_type.value == 'email' ? '邮件' : '微信'
              }}</span>
              <span class="tag">{{ row.time_text }}</span>
              <span class="tag">{{ row.template_id | templateName }}</span>
              <span class="tag">
                <el-tooltip
                  effect="dark"
                  content="修改周报方案"
                  placement="bottom"
                  ><i
                    class="iconfont icon-bianji djh"
                    @click="row.edited = !row.edited"
                    v-if="row.edited"
                  ></i>
                  <i
                    class="iconfont icon-bianji"
                    @click="planUsers(row)"
                    v-else
                  ></i
                ></el-tooltip>
              </span>
            </div>
            <div class="zbzk_bg form-box" v-show="row.edited">
              <el-form label-width="100px">
                <el-form-item label="发送方式">
                  <el-radio-group v-model="row.send_type.value">
                    <el-radio label="email" class="f-l mar-t-8">邮件</el-radio>
                    <el-select
                      @change="emailChange(row)"
                      multiple
                      collapse-tags
                      placeholder="请选择您要发送的邮件地址"
                      :class="row.redStyle.email ? 'red_style w-240' : 'w-240'"
                      v-model="row.send_type.email"
                    >
                      <el-option value="new_contact">
                        <template>
                          <i class="iconfont icon-zengjia f-l"></i>
                          <span>新增联系人</span>
                        </template>
                      </el-option>
                      <el-option
                        v-for="(item, index) in contacts.email"
                        :label="item.name"
                        :value="item.id"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <span slot="label"
                    >发送时间
                    <el-tooltip effect="dark" placement="right">
                      <i class="iconfont icon-wenhao"></i>
                      <div slot="content">
                        选择后，会以此时间过去7日的时间为基准发送周报<br />
                        如：选择每周一早上9点，则以前一周周一早上9点起至本周一<br />早上9点止的数据制作报告
                      </div>
                    </el-tooltip>
                  </span>
                  <el-select
                    placeholder="请选择发送时间"
                    class="mar-r-10 w-120"
                    v-model="row.run_week"
                  >
                    <el-option
                      :value="week.key"
                      :label="week.label"
                      v-for="(week, index) in weekMap"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <el-select
                    placeholder="请选择发送时间"
                    class="w-120"
                    v-model="row.run_sec"
                  >
                    <el-option
                      :value="hourFormat(index, 'second')"
                      :label="hourFormat(index, 'hour')"
                      v-for="(n, index) in 24"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="周报模版">
                  <el-radio-group
                    v-model="row.template_id"
                    @change="templateChange(row)"
                  >
                    <div>
                      <el-radio label="classic" class="f-l mar-t-10"
                        >经典模版</el-radio
                      >
                      <i
                        class="iconfont icon-chakan"
                        @click="catTemplate('classic')"
                      ></i>
                    </div>
                    <div>
                      <el-radio label="abstract" class="f-l mar-t-10"
                        >信息摘要模版</el-radio
                      >
                      <i
                        class="iconfont icon-chakan"
                        @click="catTemplate('abstract')"
                      ></i>
                    </div>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="副标题">
                  <el-input
                    type="text"
                    placeholder="（非必填）请输入副标题"
                    v-model="row.subtitle"
                    maxlength="30"
                    show-word-limit
                    class="w-430"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="主题颜色">
                  <div class="theme_colors">
                    <div v-for="item in themeList" :key="item.label">
                      <el-radio v-model="row.theme_color" :label="item.label">{{
                        item.name
                      }}</el-radio>
                      <span
                        :style="{ background: item.color }"
                        class="item-theme-preview"
                      ></span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="发送条件">
                  <ul class="fstj">
                    <li>
                      <span>信息等级</span>
                      <el-radio-group
                        class="mar-l-20 mar-t-8"
                        v-model="row.filter.grade.value"
                      >
                        <el-radio :label="row.filter.grade.option.join(',')"
                          >全部</el-radio
                        >
                        <el-radio label="important">精准信息</el-radio>
                        <el-radio label="common">噪音信息</el-radio>
                      </el-radio-group>
                    </li>
                    <li>
                      <span>情感属性</span>
                      <el-radio-group
                        class="mar-l-20 mar-t-8"
                        v-model="row.filter.emotion.value"
                      >
                        <el-radio :label="row.filter.emotion.option.join(',')"
                          >全部</el-radio
                        >
                        <el-radio label="negative">敏感</el-radio>
                        <el-radio label="positive">非敏感</el-radio>
                      </el-radio-group>
                    </li>
                    <li class="flex mtlx">
                      <span class="mar-r-20">媒体类型</span>
                      <div class="flex flex-1 mar-t-5">
                        <el-checkbox
                          class="mar-l-20 mar-r-20"
                          :indeterminate="row.filter.source.isIndeterminate"
                          v-model="row.filter.source.checkAll"
                          @change="mediaSourceChange(row)"
                          >全选</el-checkbox
                        >
                        <el-checkbox-group
                          class="mar-l-20"
                          v-model="row.filter.source.value"
                          @change="mediaSourceCheckBoxChange(row)"
                        >
                          <el-checkbox
                            :label="media"
                            v-for="(media, index) in row.filter.source.option"
                            :key="index"
                            >{{ media }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </div>
                    </li>
                  </ul>
                </el-form-item>
              </el-form>
              <el-row class="button-group">
                <el-button
                  :class="{ jy: row.disabled }"
                  type="primary"
                  round
                  :disabled="row.loading || row.disabled"
                  v-loading="row.loading"
                  @click="save(row)"
                  >确定</el-button
                >
                <el-button round @click="row.edited = false">取消</el-button>
              </el-row>
            </div>
          </li>
        </ul>
      </div>
      <contact-dialog v-on:addDone="loadContacts" ref="contact" />

      <el-dialog
        :visible.sync="limitDialog.visible"
        class="Dialog_style2 kyzh"
        :close-on-click-modal="false"
      >
        <el-row class="w-100 dp_box">
          <el-col :span="13">
            <div class="img">未购买此模板</div>
          </el-col>
          <el-col :span="11">
            <p class="mar-t-20">
              您购买的<strong>{{ bundle.bundle_name }}</strong>
            </p>
            <p class="mar-t-10">您没有开通该模版，</p>
            <p>购买请联系相关人员</p>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>
<script type="application/javascript">
import { themeList } from '@/utils/constants';
import contactDialog from '@components/common/new-contacts.vue';
import { getWeek, storeWeek, updateWeek } from '@/api/autobrief';
import { getContacts } from '@/api/contacts';
import { getMenuForId } from '@/utils/helpers';
import { mapGetters } from 'vuex';
const _ = window._;
export default {
  name: 'autobrief-week',
  components: {
    contactDialog
  },
  data() {
    return {
      themeList,
      data: [],
      loading: true,
      contacts: {
        contacts: [],
        wechat: [],
        email: []
      },
      weekMap: [
        { key: '0', label: '每周日' },
        { key: '1', label: '每周一' },
        { key: '2', label: '每周二' },
        { key: '3', label: '每周三' },
        { key: '4', label: '每周四' },
        { key: '5', label: '每周五' },
        { key: '6', label: '每周六' }
      ],
      briefRight: {
        abstract: false,
        classic: false
      },
      limitDialog: {
        visible: false
      }
    };
  },
  computed: {
    ...mapGetters(['menus', 'bundle'])
  },
  mounted() {
    // id 7为智能制作
    const brief = getMenuForId(this.menus, 7);
    if (brief !== undefined) {
      Object.assign(this.briefRight, brief.submodule.weeklyReport);
    }

    this.loadBrief();
    this.loadContacts();
  },
  filters: {
    templateName(type) {
      const template = { classic: '经典模板', abstract: '信息摘要模板' };

      return template[type];
    }
  },
  methods: {
    loadBrief() {
      this.loading = true;
      getWeek()
        .then(res => {
          if (res.data.state) {
            this.data = res.data.data.map(item => {
              item.edited = false;
              item.redStyle = { email: false, enable: false };
              item.filter = JSON.parse(item.filter);
              item.filter.grade.value = item.filter.grade.value.join(',');
              item.filter.emotion.value = item.filter.emotion.value.join(',');

              if (item.filter.hasOwnProperty('msg_level')) {
                item.filter.msg_level.value = item.filter.msg_level.value.join(
                  ','
                );
              } else {
                item.filter.msg_level = {
                  value: 'all',
                  option: ['all', 'normal', 'garbage']
                };
              }

              item.send_type = JSON.parse(item.send_type);
              item.send_type.value = item.send_type.value.join(','); // 发送方式单选
              item.filter.source.checkAll =
                item.filter.source.value.length ==
                item.filter.source.option.length;
              item.filter.source.isIndeterminate =
                item.filter.source.value.length > 0 &&
                item.filter.source.value.length <
                  item.filter.source.option.length;
              let hour = parseInt(item.run_sec / 60 / 60);
              let week = _.find(this.weekMap, o => {
                // 7为每一天
                item.run_week =
                  parseInt(item.run_week) === 7 ? '6' : item.run_week;
                return parseInt(o.key) === parseInt(item.run_week);
              });
              let hour_text =
                hour <= 12 ? '上午' + hour + '点' : '下午' + hour + '点';
              item.time_text = week.label + hour_text;
              item.loading = false;
              item.disabled = false;

              return item;
            });
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(res => {
          this.$message.error('加载日报失败，服务错误');
        });
    },
    templateChange(row) {
      if (!this.briefRight[row.template_id]) {
        row.disabled = true;
        this.limitDialog.visible = true;
      } else {
        row.disabled = false;
      }
    },
    loadContacts() {
      getContacts()
        .then(res => {
          if (res.data.state) {
            let email = [];
            let wechat = [];
            let result = res.data.data;
            _(result).forEach((item, i) => {
              if (item.email) {
                email.push({
                  id: item.id,
                  email: item.email,
                  name: item.name
                });
              }
              if (item.openid) {
                wechat.push({
                  id: item.id,
                  name: item.name
                });
              }
            });
            this.contacts.contacts = result;
            this.contacts.email = email;
            this.contacts.wechat = wechat;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          window.console.error(res);
          this.$message.error('加载日报失败，服务错误');
        });
    },
    planUsers(row) {
      row.edited = !row.edited;
    },
    save(row) {
      let data = JSON.parse(JSON.stringify(row));
      data.filter.grade.value = data.filter.grade.value.split(',');
      data.filter.emotion.value = data.filter.emotion.value.split(',');
      data.filter.msg_level.value = data.filter.msg_level.value.split(',');
      delete data.filter.source.checkAll;
      delete data.filter.source.isIndeterminate;
      delete data.time_text;
      delete data.created;
      delete data.updated;
      delete data.redStyle;
      if (_.size(data.send_type[data.send_type.value]) < 1) {
        this.$message.warning('请选择要发送的联系人');
        return false;
      }
      if (_.size(data.filter.source.value) < 1) {
        this.$message.warning('请选择至少一种媒体来源');
        return false;
      }
      data.send_type.value = data.send_type.value.split(',');
      data.filter = JSON.stringify(data.filter);
      data.send_type = JSON.stringify(data.send_type);
      row.loading = true;
      storeWeek(data)
        .then(res => {
          if (res.data.state) {
            this.$message.success('保存成功');
            // 启用报告钩子
            if (row.redStyle.enable) {
              row.enable = 'on';
              this.changeState(row);
              row.redStyle.enable = false;
            }
            this.loadBrief();
          } else {
            this.$message.error(res.data.error);
          }
          row.loading = false;
        })
        .catch(res => {
          this.$message.error('保存失败，服务错误');
        });
    },
    emailChange(row) {
      if (row.send_type.email.indexOf('new_contact') !== -1) {
        this.$refs.contact.show();
        row.send_type.email = row.send_type.email.filter(function(email) {
          return email !== 'new_contact';
        });
      }
      row.redStyle.email = _.size(row.send_type.email) < 1;
    },
    changeState(row) {
      if (_.size(row.send_type[row.send_type.value]) < 1) {
        row.enable = 'off';
        row.redStyle.email = true;
        row.redStyle.enable = true;
        row.edited = true;
        this.$message.warning('请选择要发送的联系人');
        return false;
      }
      if (_.size(row.filter.source.value) < 1) {
        row.enable = 'off';
        row.edited = true;
        row.redStyle.enable = true;
        this.$message.warning('请选择至少一种媒体来源');
        return false;
      }
      updateWeek({
        id: row.id,
        enable: row.enable
      })
        .then(res => {
          if (res.data.state) {
            this.$message.success('更改周报状态成功');
          } else {
            row.enable = 'off';
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('更改报告状态失败，服务错误');
        });
    },
    toPlan() {
      this.$router.push({ path: '/yq' });
    },
    catTemplate(type) {
      switch (type) {
        case 'classic':
          window.open('/autobriefing/week/classic', '_blank');
          break;
        case 'abstract':
          window.open('/autobriefing/week/abstract', '_blank');
          break;
      }
    },
    hourFormat(hour, type) {
      var val = '';
      switch (type) {
        case 'second':
          val = parseInt(hour) * 3600;
          break;
        case 'hour':
          val = hour < 10 ? '0' + hour + ':00' : hour + ':00';
          break;
        default:
          window.console.error('format type not allowed');
      }
      return val;
    },
    mediaSourceChange(row) {
      if (row.filter.source.checkAll) {
        row.filter.source.value = row.filter.source.option;
      } else {
        row.filter.source.value = [];
      }
      row.filter.source.isIndeterminate = false;
    },
    mediaSourceCheckBoxChange(row) {
      row.filter.source.checkAll =
        row.filter.source.value.length == row.filter.source.option.length;
      row.filter.source.isIndeterminate =
        row.filter.source.value.length > 0 &&
        row.filter.source.value.length < row.filter.source.option.length;
    }
  }
};
</script>
<style scoped>
.week-management li {
  height: auto;
}
.week-management >>> .el-radio-group {
  line-height: 32px;
}
.zmfs >>> .el-form-item__content {
  margin-top: 10px;
}
.fstj li {
  line-height: 32px;
  height: auto;
}
.fstj li span {
  margin-right: 20px;
  font-size: 12px;
}
.week-management .mtlx >>> .el-checkbox__label {
  float: none;
}
.week-management .mtlx >>> .el-checkbox__inner {
  margin-top: 5px;
}
.week-management >>> .el-button span {
  float: none;
}
</style>
