<template>
  <div class="w-100">
    <div class="min-h-600 rbmb">
      <h3>日报模版</h3>
      <ul class="img_list">
        <li><a href="/autobriefing/day/classic" target="_blank" class="rbjd">日报经典模板</a></li>
        <li><a href="/autobriefing/day/advanced" target="_blank" class="rbgj">日报高级模板</a></li>
        <li><a href="/autobriefing/day/abstract" target="_blank" class="rb_xxzy">信息摘要模板</a></li>
      </ul>
      <div>
        <h3 class="mar-t-10">周报模版</h3>
        <ul class="img_list">
          <li><a  href="/autobriefing/week/classic" target="_blank" class="zbjd">周报经典模板</a></li>
          <li><a  href="/autobriefing/week/abstract" target="_blank" class="zb_xxzy">信息摘要模板</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
  export default {
    name: "autobrief-template",
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>
<style scoped>
.rbmb h3{font-size: 12px;line-height:40px;}
.img_list{overflow: hidden;width: 100%;}
.img_list li{float:left;margin-right:30px;display: inline;}
.rbmb .img_list a{width: 120px;height: 158px;display: block;text-indent: -9999px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.rbmb .img_list .rbjd{background-image: url(../../../assets/images/autobrief/rbjd_mb.png)}
.rbmb .img_list .rbgj{background-image: url(../../../assets/images/autobrief/rbgj_mb.png)}
.rbmb .img_list .rb_xxzy{background-image: url(../../../assets/images/autobrief/xxzy_rb.png)}
.rbmb .img_list .zbjd{background-image: url(../../../assets/images/autobrief/zbjd_mb.png)}
.rbmb .img_list .zb_xxzy{background-image: url(../../../assets/images/autobrief/xxzy_zb.png)}
</style>
