<template>
    <el-container>
        <div class="w-100">
          <div class="content">
            <el-card class="box-card">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="自动日报管理" name="first">
                  <autobrief-day></autobrief-day>
                </el-tab-pane>
                <el-tab-pane label="自动周报管理" name="second">
                  <autobrief-week></autobrief-week>
                </el-tab-pane>
                <el-tab-pane label="模板管理" name="third">
                  <autobrief-template></autobrief-template>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </div>
            <el-row class="t-l hide">
                <el-radio-group v-model="index" size="medium" @change="handleClick" class="qhan">
                    <el-radio-button label="day">自动日报管理</el-radio-button>
                    <el-radio-button label="week">自动日报管理</el-radio-button>
                    <el-radio-button label="template">模板管理</el-radio-button>
                </el-radio-group>
            </el-row>

            <el-row class="hide">
                <autobrief-day v-if="index == 'day'"></autobrief-day>
                <autobrief-week v-if="index == 'week'"></autobrief-week>
                <autobrief-template v-if="index == 'template'"></autobrief-template>
            </el-row>
        </div>
    </el-container>
</template>
<script type="application/javascript">
  import Day from "./modules/day.vue"
  import Week from "./modules/week.vue"
  import Template from "./modules/template.vue"

  export default {
    name: "autobrief",
    components: {
      "autobrief-day": Day,
      "autobrief-week": Week,
      "autobrief-template": Template,
    },
    data() {
      return {
        index: "day",
        activeName: 'first'
      }
    },
    mounted() {
    },
    methods: {
      handleClick(tab, event) {
      },
      changeTab(index) {
        this.index = index
      }
    }
  }
</script>
<style scoped>
</style>
