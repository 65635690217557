<template>
  <div>
    <div ref="qrcode" class="item-qrcode-img"></div>
    <div class="item-tips" v-if="text">{{ text }}</div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
export default {
  name: 'Qrcode',
  props: {
    url: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.creareQrcode();
  },
  methods: {
    creareQrcode() {
      this.$nextTick(() => {
        const qrcodeEle = this.$refs.qrcode;
        const qrcode = new QRCode(qrcodeEle, {
          text: '',
          width: 100,
          height: 100,
          colorDark: '#000000',
          colorLight: '#ffffff'
        });
        qrcode.makeCode(this.url);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
#qrcode-box {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
  height: 120px;
  margin-bottom: 8px;
}
.item-tips {
  text-align: center;
  font-size: 14px;
}
.item-qrcode-img {
  display: flex;
  justify-content: center;
}
</style>
